/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import GrowthUnlockTop from '../components/unlockgrowthtop';
import Louisbester from '../assets/images/Louis-bester.jpg';
import Stephangrobler from '../assets/images/Stephan-grobler.jpg';
import { Helmet } from 'react-helmet';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const unblockgrowth = (props) => {
  const unblockgrowthImages = useStaticQuery(graphql`
    query unblockgrowthImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage grey'>
      <Layout>
        <Helmet>
          <title>Urbian | Unlock growth</title>
        </Helmet>
        <GrowthUnlockTop></GrowthUnlockTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  Using the ‘job to be done’ framework, we help you position
                  your product or service into to their life.
                </p>
                <p>
                  Meet a strategist and find out how you can quickly find the
                  insights you need to start growth hacking marketing
                  experiments to unlock that hidden growth.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>
                  Chief Marketing Officers and Product Owners who are needing to
                  unlock growth for existing product or service offerings.
                </p>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>
                    We look at your metrics and customer data and provide a
                    backlog of innovative growth hacking ideas for testing
                  </li>
                  <li>
                    We help you find the key customer journeys needed to move
                    the needle
                  </li>
                  <li>
                    We help you implement the marketing tooling into your
                    existing properties for visibility
                  </li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>A Product Owner or Delivery Lead.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={unblockgrowthImages.louis.childImageSharp.sizes}
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={unblockgrowthImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default unblockgrowth;
